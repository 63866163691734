<template>
  <div class="cell-click">
    <span v-text="textName" @click="click"></span>
  </div>
</template>

<script>
export default {
  name: 'cell-click',
  props: {
    scope: Object,
    prop: [String, Function],
  },
  data() {
    //console.log(this)
    return {}
  },
  computed: {
    textName() {
      if (typeof this.prop == 'function') return this.prop(this.scope)
      return this.scope.row[this.prop]
    },
  },
  methods: {
    click() {
      this.$emit('click', this.scope)
    },
  },
}
</script>

<style>
.cell-click span {
  font-size: 14px;
  line-height: 24px;
  vertical-align: middle;
  font-family: 'San Francisco Display';
  color: #333333;
  cursor: pointer;
}
.cell-click span:hover {
  color: #09aaff;
  text-decoration: underline;
}
</style>
