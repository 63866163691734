<template>
  <div class="app">
    <left-menu
      v-if="logged"
      :menus="menus"
      :autoCollapse="false"
      :show-more-btn="false"
    >
    </left-menu>
    <login
      v-else
      @success="loginSuccess"
      url="http://keystone.xtooltech.com/signin/login"
      domain="Xtooltech"
      project="YR7xGVyMkY4ZGVamJPlL"
    ></login>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      logged: false,
    }
  },
  computed: {
    menus: {
      get() {
        return this.$store.state.menus
      },
      set(newVal) {
        this.$store.commit('setMenus', newVal)
      },
    },
  },
  created() {
    var token = this.$token
    if (token) this.loginSuccess(token)
  },
  methods: {
    loginSuccess(token) {
      this.logged = true
    },
  },
  watch: {
    $route: function (to, from) {
      //console.log(this.$route)
      //console.log(to.query.token)
      if (to.query.token) {
        var token = this.$checkToken()
        if (token) this.loginSuccess(token)
      }
    },
  },
}
</script>

<style>
.app {
  height: 100%;
}
</style>
