<template>
  <div style="height: 100%">
    <cus-base-table
      ref="table"
      url="/advancer/getAny/"
      downUrl="/advancer/export/"
      :toolbar="toolbar"
      :columns="columns"
      :showRefreshBtn="false"
    ></cus-base-table>
    <cus-dialog :show.sync="show" :content="content"></cus-dialog>
  </div>
</template>

<script>
import axios from 'axios'
//var map=new Map();
export default {
  name: 'advancer',
  route: {
    keepAlive(to, from) {
      // console.log(this, to, from)
      // console.log(this.app.$store)
      this.app.$store.commit('replaceKeepAlive', 'advancer')
    },
  },
  activated() {
    setTimeout(() => {
      document.querySelector(
        '.el-table__body-wrapper.is-scrolling-none'
      ).scrollTop = this.scrollTop
    }, 10)
  },
  data() {
    var that = this

    return {
      scrollTop: 0,
      show: false,
      content: '',
      toolbar: {
        formItems: [
          {
            label: '用户名',
            prop: 'username',
            bind: { style: { width: '100px' } },
          },
          { label: '邮箱', prop: 'email', bind: { style: { width: '100px' } } },
          {
            label: '反馈类型',
            prop: 'type',
            value: '0',
            component: 'form-select',
            bind: {
              style: { width: '100px' },
              options: this.$getOptions('ftype'),
            },
          },
          {
            label: '状态',
            prop: 'state',
            component: 'form-select',
            bind: {
              style: { width: '100px' },
              clearable: true,
              options: [
                { label: '待处理', value: 1 },
                { label: '处理中', value: 2 },
                { label: '已解决', value: 3 },
              ],
            },
          },
          {
            label: '开始时间',
            prop: 'startTime',
            component: 'el-date-picker',
            bind: {
              type: 'date',
              valueFormat: 'yyyy-MM-dd',
              style: { width: '130px' },
            },
          },
          {
            label: '结束时间',
            prop: 'endTime',
            component: 'el-date-picker',
            bind: {
              type: 'date',
              valueFormat: 'yyyy-MM-dd',
              style: { width: '130px' },
            },
          },
        ],

        query: {
          SortFields: JSON.stringify([{ Field: 'createDate', Order: -1 }]),
        },
      },
      columns: [
        { bind: { label: '用户名', prop: 'username' } },
        { bind: { label: '手机号', prop: 'phone'} },
        { bind: { label: '邮箱', prop: 'email' } },
        {
          bind: {
            label: '反馈内容',
            prop: 'content',
          },
          default: {
            component: 'cell-click',
            bind: {
              prop: 'content',
            },
            on: { click: that.showDetail },
          },
        },
        {
          bind: { label: '反馈类型', prop: 'type' },
          format: that.TypeFormatter,
        },
        { bind: { label: '反馈时间', prop: 'date' } },
        { bind: { label: '状态', prop: 'state' }, format: that.StateFormatter },
        {
          bind: { label: '操作', width: '100px' },
          default: {
            component: 'CellDropdown',
            bind: {
              dropdowns: [
                { label: '详情', click: that.showDetail },
                { label: '下载日志', click: that.download },
                { label: '删除', click: that.delData },
              ],
            },
            on: { click: that.delData },
          },
        },
      ],
    }
  },
  methods: {
    showDialog(row) {
      var that = this
      that.show = true
      that.content = row.content
    },
    showDetail(scope) {
      //   this.$router.replace("/feedback-detail");
      this.scrollTop = document.querySelector(
        '.el-table__body-wrapper.is-scrolling-none'
      ).scrollTop
      var detail = Object.assign(
        {
          stateText: this.StateFormatter(scope.row.state),
          typeText: this.TypeFormatter(scope.row.type),
        },
        scope.row
      )
      this.$router.push({
        name: 'AdvancerDetail',
        params: {
          detail,
        },
      })
    },
    StateFormatter(val) {
      switch (parseInt(val)) {
        case 1:
          return '待处理'
        case 2:
          return '处理中'
        case 3:
          return '已解决'
        default:
          return ''
      }
    },
    TypeFormatter(val) {
      if (isNaN(val)) return ''
      return this.$store.state.ftype[val] || ''
    },
    delData(scope) {
      if (confirm('确定删除?')) {
        var that = this
        let replyCount = 0
        const replyQueryUrl = '/advancer/getreply/' + scope.row._id
        axios.post(replyQueryUrl).then((response) => {
          if (response.data.code == 0) {
            replyCount = response.data.data.length
          }
          if (replyCount > 0) {
            this.$message('删除失败,已回复的反馈不可删除！')
          } else {
            const deleteUrl = '/advancer/' + scope.row._id
            axios
              .post(deleteUrl)
              .then((response) => {
                if (response.data.code == 0) {
                  that.$refs.table.delRow(scope.row)
                  //this.$message(response.data.msg);
                } else {
                  this.$message(response.data.msg)
                }
              })
              .catch((error) => {
                this.$message('删除失败：请求发生错误！')
                console.log(error)
              })
          }
        })
      }
    },
    download(scope) {
      const link = document.createElement('a')
      link.setAttribute('download', '文件数据') //下载的文件名
      link.href = scope.row.logUrl //文件url
      link.click()
    },
  },
  mounted() {
    var that = this
  },
}
</script>

<style>
/* .el-form-item__content {
  width: 100px;
} 
.el-form-item .el-form-item--small {
  width: 40%;
  margin-right: 1px;
}
.el-form--inline .el-form-item {
  display: inline-block;
  margin-right: 1px;
  vertical-align: top;
}
.el-form-item__label {
  padding: 0 5px 0 0;
}*/
</style>
