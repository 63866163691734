<template>
  <div>
    <el-dialog
      title="查看"
      :visible.sync="visible"
      style="margin-top: 25vh"
      @close="$emit('update:show', false)"
      :show="show"
    >
      <span>
        {{ content }}
      </span>
    </el-dialog>
  </div>
</template>
 
<script>
export default {
  name: 'cus-dialog',
  data() {
    return {
      visible: this.show,
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      required: true,
    },
  },
  watch: {
    show() {
      this.visible = this.show
    },
  },
}
</script>

<style>
.el-dialog {
  min-height: 150px;
  margin: 0vh 35%;
  width: 500px;
}
.el-dialog__title {
  line-height: 20px;
  font-size: 16px;
}
.el-dialog__body {
  padding: 15px 20px;
}
</style>