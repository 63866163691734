<template>
  <div class="reply-item">
    <img class="face" src="../assets/img/face.png" />
    <div class="reply-content">
      <div class="reply-name">
        {{ data.name }}
        <span class="date">{{ data.date }}</span>
      </div>
      <div class="reply">{{ data.content }}</div>
      <div v-if="imgs && imgs.length > 0" class="reply-imgs">
        <el-image
          v-for="(item, index) in imgs"
          :key="index"
          :src="item"
          fit="cover"
          :preview-src-list="imgs"
        ></el-image>
      </div>
    </div>
    <!-- <div class="date">{{data.date}}</div> -->
  </div>
</template>
<script>
export default {
  name: 'reply-item',
  props: {
    data: Object,
  },
  computed: {
    imgs() {
      var ret = []
      if (this.data && Array.isArray(this.data.imgs)) {
        for (let index = 0; index < this.data.imgs.length; index++) {
          ret.push('http://down.xtooltech.com/' + this.data.imgs[index])
        }
      }

      return ret
    },
  },
}
</script>
<style lang="less" scoped>
.reply-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .face {
    width: 40px;
    height: 40px;
    margin-right: 30px;
  }
  .reply-content {
    flex-grow: 1;
    .reply-name,
    .reply {
      font-size: 14px;
      color: #666;
    }
    .reply-name {
      color: #000;
      margin-bottom: 8px;
    }
    .reply-imgs {
      margin-top: 5px;
      .el-image {
        width: 76px;
        height: 56px;
        margin-right: 15px;
        margin-bottom: 15px;
      }
    }
  }
  .date {
    margin-left: 30px;
    font-size: 14px;
    color: #999;
  }
}
</style>