<template>
  <span v-bind="$attrs">{{ text }}</span>
</template>
<script>
import moment from 'moment'
export default {
  name: 'gtm8-date',
  props: {
    scope: Object,
  },
  computed: {
    text() {
      var date = this.scope.row[this.scope.column.property]
      if (date) {
        date = moment.utc(date)
        return date.local().format('YYYY-MM-DD HH:mm:ss')
      }
      return ''
    },
  },
}
</script>