<template>
  <div style="height: 100%">
    <cus-base-table
      ref="ObdDataTable"
      url="/advancer/getObdInfo/"
      :toolbar="toolbar"
      :columns="columns"
    ></cus-base-table>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AdvancerOBDList',
  props: {},
  data() {
    var that = this
    return {
      toolbar: {
        formItems: [
          {
            label: '用户名',
            prop: 'username',
            bind: { style: { width: '100px' } },
          },
          { label: '邮箱', prop: 'email', bind: { style: { width: '100px' } } },
          {
            label: '手机品牌',
            prop: 'phonebrand',
            bind: { style: { width: '100px' } },
          },
          {
            label: '手机型号',
            prop: 'phonemodel',
            bind: { style: { width: '100px' } },
          },
          {
            label: '日志类型',
            prop: 'type',
            value: '',
            component: 'form-select',
            bind: {
              style: { width: '100px' },
              options: [
                { label: '全部', value: '' },
                { label: '测试日志', value: 1 },
              ],
            },
          },
        ],
        query: {
          //type: null,
        },
      },
      columns: [
        { bind: { label: '内容', prop: 'content' } },
        { bind: { label: '用户名', prop: 'username' } },
        { bind: { label: '邮箱', prop: 'email' } },
        { bind: { label: 'APP版本', prop: 'appver' } },
        { bind: { label: '固件版本', prop: 'boxver' } },
        { bind: { label: '手机品牌', prop: 'phonebrand' } },
        { bind: { label: '手机型号', prop: 'phonemodel' } },
        { bind: { label: '客户端IP', prop: 'clientip' } },
        { bind: { label: '操作系统版本', prop: 'osver' } },
        { bind: { label: '蓝牙连接状态', prop: 'btstate' } },
        { bind: { label: '车辆协议', prop: 'carprotocol' } },
        { bind: { label: 'VIN', prop: 'vin' } },
        { bind: { label: '上报时间', prop: 'date' } },
        {
          bind: { label: '操作', width: '100px' },
          default: {
            component: 'cell-buttons',
            bind: {
              buttons: [
                { label: '下载', type: 'text', click: that.download },
                { label: '删除', type: 'text', click: that.delData },
              ],
            },
            on: { click: that.delData },
          },
        },
      ],
    }
  },
  methods: {
    download(scope) {
      if (!scope.row.attachment) {
        this.$message('app未上传日志文件')
        return
      }
      const link = document.createElement('a')
      link.setAttribute('download', '文件数据') //下载的文件名
      link.href = scope.row.attachment //文件url
      link.click()
    },
    delData(scope) {
      if (confirm('确定删除?')) {
        var that = this
        const deleteUrl = '/advancer/deleteObd/' + scope.row._id.toString()
        axios
          .post(deleteUrl)
          .then((response) => {
            if (response.data.code == 0) {
              that.$refs.ObdDataTable.delRow(scope.row)
            } else {
              this.$message(response.data.msg)
            }
          })
          .catch((error) => {
            this.$message('删除失败：请求发生错误！')
            console.log(error)
          })
      }
    },
  },
}
</script>

<style>
</style>
