import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 2000 }

//import Element from 'element-ui'
import { PageHeader, Menu,Submenu,MenuItem,Select,Option,Button,ButtonGroup,Form,FormItem,Input,Dropdown,DropdownMenu,DropdownItem,Tabs,TabPane,Table,Loading,TableColumn,Pagination,Drawer,Breadcrumb,BreadcrumbItem,Dialog,Upload,Message,DatePicker,InputNumber,Image,Popover,Notification } from 'element-ui'
// Notify.install=function(vue, opts){
//     if(!vue.prototype.$notify) vue.prototype.$notify=Notify;
// }
Vue.prototype.Message=Message
Vue.prototype.Notify=Vue.prototype.$notify=Notification
var elementuis=[
    Menu,
    Submenu,
    MenuItem,
    Select,
    Option,
    Button,
    ButtonGroup,
    Form,
    FormItem,
    Input,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Tabs,
    TabPane,
    Table,
    Loading,
    TableColumn,
    Pagination,
    Drawer,
    Breadcrumb,
    BreadcrumbItem,
    Dialog,
    Upload,
    /*Message,*/
    DatePicker,
    InputNumber,
    Image,
    Popover,
    PageHeader
]
elementuis.forEach(element => {
    Vue.use(element)
})
