export default [
    '全部',
    '蓝牙连接超时',
    '蓝牙连接失败',
    '无法识别车辆',
    '读取车辆数据失败',
    '无法读取车辆数据/数据不全',
    '闪退',
    '卡顿/卡死',
    '功能使用',
    '改进建议',
    '其他',
]