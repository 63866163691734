import './assets/index.css'
import Vue from 'vue'
Vue.config.productionTip = false

/*注册云端通用组件*/
if (window.xtooltech) Vue.use(window.xtooltech.default)

/*导入element-ui*/
import './plugins/element'

/*注册自定义组件*/
import './components/index'

/*导入store(一定得要比router先导入)*/
import store from './store'

/*导入router*/
import router from './router/index'

new Vue({
	router,
	store,
	render: h => h(require('./App.vue').default)
}).$mount('#app')
