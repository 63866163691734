<template>
    <div class="field-item">
        <span v-if="label">{{label + '：'}}</span>
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'field-item',
    props:{
        label: String
    }
}
</script>
<style lang="less">
.field-item{
    font-size: 14px;
}
</style>