
import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)

const matchRouter = function (currentRoute, location) {
    if (typeof location == 'object') {
        return currentRoute.path == location.path || currentRoute.name == location.name
    } else {
        return currentRoute.path == location
    }
}

const vueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    if (matchRouter(this.currentRoute, location)) {
        this.app.$store.commit('routeReload')
    } else {
        return vueRouterPush.apply(this, arguments)
    }
}

const routes = []
const context = require.context('../pages/', false, /\.(vue)$/)
context.keys().forEach((filename) => {
    // console.log(filename)
    const item = context(filename).default
    var exec = /\.\/(\w+)\.vue$/i.exec(filename)
    if (exec) {
        var name = exec[1]
        var route = item.route || {}
        routes.push(Object.assign({ name, path: '/' + name, props: true, component: () => import(/* webpackChunkName: "pages" */ '../pages/' + name + '.vue') }, route))
    }
})

const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes: [
        { path: '/', redirect: '/Advancer' }
    ].concat(routes)
})
router.beforeEach((to, from, next) => {
    for (let i = 0; i < router.options.routes.length; i++) {
        const item = router.options.routes[i]
        if (matchRouter(item, to)) {
            if (typeof item.keepAlive == 'function') {
                try {
                    item.keepAlive.call(router, item, from)
                } catch (error) {
                    console.error(error)
                }
            }
            break
        }
    }
    next()
})
export default router


