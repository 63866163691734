import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    routeKey: 1,
    menus: require('./menus.js').default,
    ftype: require('./ftype.js').default,
    uploadData: null,
    keepAliveInclude: [],
  },
  mutations: {
    routeReload(state) {
      state.routeKey++;
    },
    setUploadData(state, payload) {
      state.uploadData = payload;
    },
    addKeepAlive(state, componentName) {
      if (state.keepAliveInclude.indexOf(componentName) < 0) {
        state.keepAliveInclude.push(componentName)
      }
    },
    removeKeepAlive(state, componentName) {
      var index = state.keepAliveInclude.indexOf(componentName)
      if (index > -1) {
        state.keepAliveInclude.splice(index, 1)
      }
    },
    clearKeepAlive(state) {
      if (state.keepAliveInclude.length > 0) {
        state.keepAliveInclude = []
      }
    },
    replaceKeepAlive(state, componentName) {
      if (state.keepAliveInclude.length == 1 && state.keepAliveInclude[0] == componentName) {
        return
      }
      state.keepAliveInclude = [componentName]
    }
  },
  actions: {
  },
  modules: {
  }
})
