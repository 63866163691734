<template>
  <div class="advancer-detail">
    <el-page-header @back="$router.go(-1)" content="反馈详情" style="margin-bottom: 30px;"></el-page-header>
    <div class="main-content">
      <div>
        <h3>{{ detail.content }}</h3>
        <div class="main-content-img">
          <el-image
            v-for="(item, index) in imgs"
            :key="index"
            :src="item"
            fit="cover"
            :preview-src-list="imgs"
          ></el-image>
        </div>
      </div>
      <div>
        <h3>基本信息</h3>
        <div class="field-wrap">
          <field-item  v-for="(item, key) in baseInfo" :key="key" :label="detail[key]?item:null" >{{ detail[key] }}</field-item>
        </div>
      </div>
      <div>
        <h3>版本信息</h3>
        <div class="field-wrap">
          <field-item v-for="(item, key) in verInfo" :key="key" :label="detail[key]?item:null" >
            {{
            detail[key]
            }}
          </field-item>
        </div>
      </div>
      <div>
        <h3>车辆信息</h3>
        <div class="field-wrap">
          <field-item v-for="(item, key) in carInfo" :key="key" :label="detail[key]?item:null" >
            {{
            detail[key]
            }}
          </field-item>
        </div>
      </div>
      <div>
        <h3>回复（{{ replyCount }}）</h3>
        <div style="padding: 15px 40px; width: 61.8%; min-width: 800px;">
          <reply-item v-for="(item, index) in replies" :key="index" :data="item"></reply-item>
        </div>
      </div>
    </div>
    <div v-if="detail.state != 3" style="width: 38.2%; min-width: 400px;">
      <div style="margin-bottom: 10px;">回复信息</div>
      <el-input
        type="textarea"
        maxlength="300"
        rows="8"
        show-word-limit
        v-model="replyData.content"
      ></el-input>
      <div style="text-align: right; margin-top: 10px;">
        <el-button type="primary" @click="submitForm">回复</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "advancer-detail",
  props: {
    detail: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      baseInfo: {
        stateText: "状态",
        typeText: "问题类型",
        date: "创建时间",
        username: "用户昵称",
        region: "地区",
        clientip: "客户端IP",
        clientposi: "IP定位",
        email: "用户邮箱"
      },
      verInfo: {
        appver: "APP版本",
        boxver: "固件版本",
        phonebrand: "品牌",
        phonemodel: "型号",
        osver: "系统版本"
      },
      carInfo: {
        carbrand: "品牌",
        carmodel: "型号",
        caryear: "年款",
        cardisplacement: "排量",
         vin: "VIN码",
      },
      replyCount: "",
      replyData: {
        content: "",
        type: "2",
        _id: null,
        pid: this.detail._id,
        name: ""
      },
      replies: []
    };
  },
  computed: {
    imgs() {
      var ret = [];
      if (this.detail && Array.isArray(this.detail.imgs)) {
        for (let index = 0; index < this.detail.imgs.length; index++) {
          ret.push("http://down.xtooltech.com/" + this.detail.imgs[index]);
        }
      }
      return ret;
    }
  },
  created() {
    // if(Object.keys(this.detail))
    this.getreply();
  },
  mounted() {},
  methods: {
    submitForm() {
      var that = this;
      if (!this.$token) {
        this.$message("登录信息已过期，请重新登录");
        return;
      }
      if (!that.replyData.content) {
        this.$message("请输入回复内容");
        return;
      }
      var name = that.$token.nick_name;
      that.replyData.pid = that.detail._id;
      that.replyData.type = 2;
      that.replyData._id = null;
      that.replyData.name = name;
      var url = "/advancer/reply/";
      axios
        .post(url, that.replyData)
        .then(response => {
          if (response.data.code == 0) {
            this.getreply();
            this.$message({
              message: "回复成功",
              type: "success"
            });
            that.replyData.content = null;
          } else {
            this.$message("请求异常，回复失败");
            console.log(response.data.msg);
          }
        })
        .catch(error => {
          this.$message("请求发生错误！");
          console.log(error);
        });
    },
    getreply() {
      var that = this;
      // that.detail._id = localStorage.getItem("id");
      var url = "/advancer/getreply/" + that.detail._id;
      axios
        .get(url)
        .then(response => {
          if (response.data.code == 0) {
            that.replies = response.data.data || [];
            that.replyCount = that.replies.length || 0;
            // var list = that.replies.imgs;
            // var imgs = [];
            // if (list != null) {
            //   for (var i = 0; i < list.length; i++) imgs.push(api + list[i]);
            // }
            // that.replies.imgs = imgs
            // var src = "http://down.xtooltech.com/";
            // for (var i = 0; i < that.replies.length; i++) {
            //   var imgs = that.replies[i].imgs;
            //   if (imgs != null) {
            //     for (var j = 0; j < imgs.length; j++) imgs[j] = src + imgs[j];
            //     that.replies[i].imgs = imgs;
            //   }
            // }
          } else {
            this.$message(response.data.msg);
          }
        })
        .catch(error => {
          this.$message("请求发生错误！");
          console.log(error);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.advancer-detail {
  padding: 15px 20px;
  .main-content-img {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    .el-image {
      width: 108px;
      height: 86px;
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
  .field-wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;
    .field-item {
      width: 25%;
      margin-bottom: 15px;
    }
  }
}
</style>
